<template>
  <v-container class="pa-0">
    <v-card class="pa-8">
      <v-text-field
        v-model="localContent.title"
        color="primary"
        variant="underlined"
        :rules="[value => !!value || '入力必須です']"
        @input="change()"
      >
        <template #label>
          タイトル
          <span style="color: red">*</span>
        </template>
      </v-text-field>

      <v-text-field
        v-model="localContent.description"
        label="説明文"
        color="primary"
        variant="underlined"
        @input="change()"
      />

      <div class="field">
        <v-select
          v-model="localContent.status"
          item-title="label"
          item-value="value"
          :items="visibillitySettingItems"
          label="公開範囲"
          color="primary"
          variant="underlined"
          @update:model-value="change()"
        />
      </div>

      <v-select
        v-model="localContent.streamingData!.modes[0]"
        item-title="label"
        item-value="value"
        color="primary"
        label="視点選択"
        variant="underlined"
        :items="modeFormatItems"
        @update:model-value="change()"
      />

      <v-select
        v-model="localContent.deliveryFormat"
        item-title="label"
        item-value="value"
        label="配信形式"
        color="primary"
        variant="underlined"
        :disabled="!props.isNew"
        :items="deliveryFormatItems"
        @update:model-value="changeDeliveryFormat(localContent.deliveryFormat!)"
      />
    </v-card>
  </v-container>
</template>

<script lang="ts" setup>
import { ref, PropType, watch } from 'vue'

import { EditableContentFormat } from '@/views/Clips/Editor/types/EditableContentFormat'
import {
  visibillitySettingItems,
  modeFormatItems,
  deliveryFormatItems
} from '@/views/Clips/Editor/utils/SelectItemsUtil'

const props = defineProps({
  isNew: {
    type: Boolean,
    required: false,
    default: true
  },
  content: {
    type: Object as PropType<EditableContentFormat>,
    required: true
  }
})

const localContent = ref<Partial<EditableContentFormat>>(props.content)

interface Emits {
  (_event: 'change', _val: Partial<EditableContentFormat>): void
  (_event: 'changeDeliveryFormat', _val: string | undefined): void
}
const emit = defineEmits<Emits>()

const change = () => {
  emit('change', localContent.value)
}

const changeDeliveryFormat = (val: string) => {
  emit('changeDeliveryFormat', val)
}

watch(
  () => props.content,
  obj => {
    localContent.value = obj
  }
)
</script>

<style lang="scss"></style>
